import { router } from 'next/router';
import store from '../redux/store'
import { logout_unauthorized } from './auth';
import { errorLoading } from '../redux/loading.slice';

const api_headers = new Headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
});

const set_headers = (tenant) => {
    const state = store.getState()

    if (api_headers.has('Authorization')) {
        api_headers.delete('Authorization')
    }
    if (api_headers.has('x-tenant')) {
        api_headers.delete('x-tenant')
    }
    if (state.auth.token) {
        api_headers.append('Authorization', 'Bearer ' + state.auth.token)
    }
    if (state.auth.xtenant) {
        api_headers.append('x-tenant', state.auth.xtenant)
    }
    if (tenant) {
        api_headers.delete('x-tenant')
        api_headers.append('x-tenant', tenant)
    }
}

const set_headers_admin = () => {
    const state = store.getState()

    if (api_headers.has('Authorization')) {
        api_headers.delete('Authorization')
    }
    if (api_headers.has('x-tenant')) {
        api_headers.delete('x-tenant')
    }
    if (state.admin.token) {
        api_headers.append('Authorization', 'Bearer ' + state.admin.token)
    }
}

export const sd_api_get = async (url) => {
    try {
        set_headers()

        const fetched = await fetch(process.env.NEXT_PUBLIC_API_URL + url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: api_headers,
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        });

        if (!fetched.ok) {
            store.dispatch(errorLoading())
            if (fetched.status == 401) {
                // lets log them out
                // logout_unauthorized()
                // router.push('/', undefined, { shallow: true, scroll: true });
            }
            throw {
                status: fetched.status,
                data: 'error',
            }
        } else {
            return {
                status: fetched.status,
                data: await fetched.json(),
            }
        }
    } catch (err) {
        store.dispatch(errorLoading())
        throw {
            status: 'error',
            data: 'failed to fetch from remote API',
        }
    }
}

export const sd_api_post = async (url, data, tenant = null) => {
    try {
        set_headers(tenant)

        const fetched = await fetch(process.env.NEXT_PUBLIC_API_URL + url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: api_headers,
            body: JSON.stringify(data),
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        });

        if (!fetched.ok) {
            store.dispatch(errorLoading())
            if (fetched.status == 401) {
                // lets log them out
                logout_unauthorized()
                router.push('/', undefined, { shallow: true, scroll: true });
            }
            throw {
                status: fetched.status,
                data: await fetched.json(),
            }
        } else {

            return {
                status: fetched.status,
                data: await fetched.json(),
            }
        }
    } catch (err) {
        store.dispatch(errorLoading())
        throw {
            status: 'error',
            data: 'failed to fetch from remote API',
        }
    }
}

export const sd_api_post_admin = async (url, data) => {
    try {
        set_headers_admin()

        const fetched = await fetch(process.env.NEXT_PUBLIC_API_URL + url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: api_headers,
            body: JSON.stringify(data),
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        });

        if (!fetched.ok) {
            store.dispatch(errorLoading())
            if (fetched.status == 401) {
                // lets log them out
                logout_unauthorized()
                router.push('/', undefined, { shallow: true, scroll: true });
            }
            throw {
                status: fetched.status,
                data: await fetched.json(),
            }
        } else {

            return {
                status: fetched.status,
                data: await fetched.json(),
            }
        }
    } catch (err) {
        store.dispatch(errorLoading())
        throw {
            status: 'error',
            data: 'failed to fetch from remote API',
        }
    }
}


export const sd_api_delete = async (url, data, tenant = null) => {
    try {
        set_headers(tenant)

        const fetched = await fetch(process.env.NEXT_PUBLIC_API_URL + url, {
            method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: api_headers,
            body: JSON.stringify(data),
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        });

        if (!fetched.ok) {
            store.dispatch(errorLoading())
            if (fetched.status == 401) {
                // lets log them out
                logout_unauthorized()
                router.push('/', undefined, { shallow: true, scroll: true });
            }
            throw {
                status: fetched.status,
                data: await fetched.json(),
            }
        } else {
            return {
                status: fetched.status,
                data: await fetched.json(),
            }
        }
    } catch (err) {
        store.dispatch(errorLoading())
        throw {
            status: 'error',
            data: 'failed to fetch from remote API',
        }
    }

}